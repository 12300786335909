import React from 'react';

import Container from '../components/elements/container';
import Heading from '../components/elements/heading';
import Paragraph from '../components/elements/paragraph';
import Wrapper from '../components/elements/wrapper';
import Layout from '../components/layout';

export default function NotFoundPage() {
	return (
		<Layout>
			<Container>
				<Wrapper className="padding-top padding-bottom">
					<Heading color="dark" level={1}>
						Error 404 - Page not found!
					</Heading>
					<Paragraph>
						The page you are trying to reach does not exist, or has been moved. Please use the menus
						or the search box to find what you are looking for.
					</Paragraph>
				</Wrapper>
			</Container>
		</Layout>
	);
}
